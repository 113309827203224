/* color scheme */

:root {
    --main-color: #8967e9;
    --purple-hover: rgb(80, 58, 129);
    --purple-active: rgb(74, 89, 177);
}

.formio-component-content p {
    line-height: initial;
}

.formio-form input + span {
    margin-left: calc(16px + 1rem);
}

.formio-form .form-check-inline {
    margin-right: 2rem;
}

.formio-form .form-check-inline input + span {
    margin-left: 0.5rem;
}

.formio-component-content {
    font-size: 16px;
}

.formio-form:not(.smaller) .col-form-label strong,
.formio-component-content strong {
    font-size: 1.6rem;
    line-height: initial;
}

.formio-form .form-check {
    padding-left: 0px;
    font-size: 0.9rem;
    margin: 0.9rem 0rem;
}

.formio-form input[type='radio'],
.formio-form input[type='checkbox'] {
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    margin: 0;
}

.formio-form input[type='radio']::before,
.formio-form input[type='checkbox']::before {
    content: '';
    border: 1.5px solid rgb(18, 13, 61);
    width: 16px;
    height: 16px;
    display: inline-block;
    vertical-align: middle;
    box-sizing: border-box;
    transform: translateY(-1px);
}

.formio-form input[type='radio']::before {
    border-radius: 50%;
}

.formio-form input[type='radio']:checked::before,
.formio-form input[type='checkbox']:checked:before {
    content: '';
    border: 1.5px solid var(--main-color);
    background-color: var(--main-color);
}

/* Use of .form-control on text inputs is to differentiate them
   from inputs for select autocompletes.
*/
.formio-form input[type='text'].form-control,
.formio-form textarea {
    border-radius: 1px;
    font-size: 1rem;
    border: 1px solid gray;
    transition: 0.2s;
    font-weight: 400;
    outline: 1px transparent;
    padding: 10px 40px 10px 15px;
    height: auto;
    margin-top: 0.9rem;
}

.formio-form input[type='text'].form-control:hover:not(:focus),
.formio-form textarea:hover:not(:focus) {
    border: 1px solid lightgray;
    background-color: #8a67e90c;
}

.formio-form input[type='text'].form-control:focus,
.formio-form textarea:focus {
    border: 2px solid var(--main-color);
    box-shadow: 0px 0px 0px 0px !important;
}

.formio-form .table-no-border .table-bordered {
    margin-top: 1rem;
}

.formio-form .table-no-border .table-bordered,
.formio-form .table-no-border .table-bordered thead th,
.formio-form .table-no-border .table-bordered th,
.formio-form .table-no-border .table-bordered td {
    border: none;
    padding: 0 0.5rem 0.5rem 0;
}

.formio-form .table-no-border .table-bordered tbody td .btn,
.formio-form .table-no-border .table-bordered tbody td .btn .btn-secondary {
    padding: 0 0.2rem;
    font-size: 1.6rem;
    color: var(--main-color);
    background-color: transparent;
    font-weight: normal;
}

.formio-form .table-no-border .table-bordered .formio-select-autocomplete-input {
    padding: 0;
    display: none;
}

.formio-form .formio-error-wrapper {
    background-color: inherit;
    color: #ca182a;
    border-left: 3px solid #ca182a;
    margin-left: -12px;
}

.field-required:after, .tab-error::after {
    color: var(--main-color);
}

.alert-danger {
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;
    max-width: 600px;
    background-color: inherit;
    color: #ca182a;
}

/* navigation */

.formio-form .formio-wizard-nav-container {
    margin-top: 2rem;
    display: flex;
    justify-content: flex-end;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}

.formio-form .formio-wizard-nav-container li:last-child {
    margin-right: 0;
}

.form-check-label {
    display: flex;
    font-size: 16px !important;
}

/* mui classes */

.btn {
    outline: none;
    transition: 0.3s;
    border: 0px;
    border-radius: 2px;
    display: block;
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: bold;
    padding: 0.75rem 4rem;
}

.btn-primary {
    background-color: var(--main-color);
}

.btn-secondary {
    padding: 0.75rem 1rem;
}

.btn-primary:hover {
    background-color: var(--purple-hover);
}

.btn-primary:active {
    background-color: var(--purple-active) !important;
}

.btn-primary:focus {
    box-shadow: 0px 0px 0px 0px !important;
}

.MuiDialog-paperFullWidth {
    margin: 0px !important;
    width: 100% !important;
    max-width: 100% !important;
    height: 100% !important;
    max-height: 100% !important;
}

.formio-form,
.progress-bar {
    width: 100%;
    max-width: 800px;
    margin: auto;
}

.wizard-page {
    max-width: 600px;
    margin: auto;
    margin-top: 30px;
}

.progress-bar {
    height: 2px;
    background-color: lightgray;
    position: relative;
}

.progress-bar .progress {
    height: 100%;
    width: 0px;
    transition: 0.2s;
    background-color: var(--main-color);

    /* Unfortunately this doesn't work as formio replaces the header
    every page change. */
    /* transition: width 0.5s; */
}

.formio-component-tabs {
    margin-bottom: 20px;
}

.form-group {
    margin-bottom: 2rem;
}

/* pagination */

.page-link {
    border: 0px;
    border-radius: 0px;
    color: #8a67e98a !important;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 12px;
    padding: 1rem 0.75rem;
}

.page-link:focus {
    box-shadow: 0px 0px 0px 0px;
}

.page-link:hover {
    background-color: transparent;
}

.active .page-link {
    color: #8967e9 !important;
    background-color: transparent !important;
}

.page-item.active ~ .page-item .page-link {
    color: gray !important;
}
.hidden-page-item {
    display: none;
}

.pagination {
    justify-content: space-around;
}

.response-button {
    width: 275px;
    height: 60px;
    margin: auto !important;
    display: block !important;
}

.response-button.bottom {
    margin-top: 20px !important;
}

.header-wrap {
    position: sticky;
    top: 0px;
    z-index: 2;
    /* recompensate for the nav header being fixed */
    margin-bottom: 50px !important;
}

.nav-header {
    left: 50% !important;
    transform: translateX(-50%);
    max-width: 800px;
    width: 100%;
    background-color: white;
    position: fixed;
    left: 0px;
    top: 90px;
    z-index: 3;
}
